import React from 'react';
import { Link } from 'react-router-dom';

import MainHeader from './MainHeader';
import NavLinks from './NavLinks'
import './MainNavigation.css';
import logo from '../../../assets/logo.png';


const MainNavigation = props => {
    return <MainHeader>
        <button className="main-navigation__menu-btn">

        </button>
        <h1 className="main-navigation__title">
           <Link to="./"><img src={logo} className="logo" alt="logo" /></Link> 
        </h1>
        <nav>
            <NavLinks />
        </nav>
    </MainHeader>;
}

export default MainNavigation;