import React from 'react';

import fb from '../../../assets/icons/fb-i.svg';
import li from '../../../assets/icons/li-i.svg';
import tw from '../../../assets/icons/tw-i.svg';
import inst from '../../../assets/icons/in-i.svg';

import './MainFooter.css';

const MainFooter = props => {
  return <footer className="main-footer">
    <div class="container footer-top">
      <a href='/' target='_blank' rel="noopener noreferrer" ><img src={fb} className="footer-icon" alt="logo" /></a>
      <a href='https://www.linkedin.com/in/chandra-sekhar-nath-5a98ab42/' target='_blank' rel="noopener noreferrer" ><img src={li} className="footer-icon" alt="logo" /></a>
      <a href='/' target='_blank' rel="noopener noreferrer" ><img src={tw} className="footer-icon" alt="logo" /></a>
      <a href='/' target='_blank' rel="noopener noreferrer" ><img src={inst} className="footer-icon" alt="logo"/></a>
  </div>
  <div class="footer-bottom clearfix">
      <div class="copyright">
        © Copyright <strong><span>Chandra Sekhar Nath</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer>;
};

export default MainFooter;
 