import React, { useCallback, useReducer } from 'react';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE
} from '../../shared/util/validators';
import './AddEmployee.css';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'INPUT_CHANGE':
      let formIsValid = true;
      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid }
        },
        isValid: formIsValid
      };
    default:
      return state;
  }
};
const AddEmployee = () => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      empId: { 
        value: '',
        isValid: false
      },
      name: {
        value: '',
        isValid: false
      },
      email: { 
        value: '',
        isValid: false
      },
      password: { 
        value: '',
        isValid: false
      },
      phone: { 
        value: '',
        isValid: false
      },
      dob: {
        value: '',
        isValid: false
      },
      doj: { 
        value: '',
        isValid: false
      },
      depertment: {
        value: '',
        isValid: false
      },
      designation: { 
        value: '',
        isValid: false
      },
      comment: { 
        value: '',
        isValid: false
      }
    },
    isValid: false
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: 'INPUT_CHANGE',
      value: value,
      isValid: isValid,
      inputId: id
    });
  }, []);

  const EmployeeSubmitHandler = async event => {
    event.preventDefault();
    try {
      const response = await fetch('http://localhost:3000/api/employees/signup', {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:  JSON.stringify({
          empId: formState.inputs.empId.value,
          name: formState.inputs.name.value,
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
          phone: formState.inputs.phone.value,
          dob: formState.inputs.dob.value,
          doj: formState.inputs.doj.value,
          depertment: formState.inputs.depertment.value,
          designation: formState.inputs.designation.value,
          comment: formState.inputs.comment.value
        })
      });

      const responseData = await response.json();
      console.log(responseData);
    } catch(err) {

    }
    
    // console.log(formState.inputs); // send this to the backend!
  };

  return (
    <Card className="authentication EmployeeForms">
      <h2>Add Employee details</h2>
      <hr />
      <form onSubmit={EmployeeSubmitHandler}>
      <Input
          element="input"
          id="empId"
          type="text"
          placeholder="Emp ID"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter correct ID"
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="name"
          type="text"
          placeholder="Full Name"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a name."
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="email"
          type="email"
          placeholder="E-Mail"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="password"
          type="text"
          placeholder="Password"
          validators={[VALIDATOR_MAXLENGTH(6)]} 
          errorText="Please enter password"
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="phone"
          type="phone"
          placeholder="Phone"
          validators={[VALIDATOR_MINLENGTH(5)]} 
          errorText="Please enter a valid phone number"
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="dob"
          type="date"
          placeholder="Date of Birth"
          validators={[VALIDATOR_REQUIRE()]} 
          errorText="please enter Date of Birth"
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="doj"
          type="date"
          placeholder="Date of join"
          validators={[VALIDATOR_REQUIRE()]} 
          errorText="please enter Date of join"
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="department"
          type="text"
          placeholder="department"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a department"
          onInput={inputHandler}
        />
        <Input
          element="input"
          id="designation"
          type="text"
          placeholder="designation"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a designation"
          onInput={inputHandler}
        />

        <Input
          element="input"
          id="comment"
          type="text"
          placeholder="Comment"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter any comment"
          onInput={inputHandler}
        />
        
        <Button type="submit" disabled={!formState.isValid}>
          Submit
        </Button>
      </form>
    </Card>
  );
};

export default AddEmployee;
