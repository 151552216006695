import React from 'react';
import Card from '../../shared/components/UIElements/Card';
import Button from '../../shared/components/FormElements/Button';

import './Employee.css';

  const Employee = props => {
    return (
        <div>
          {props.items.map(employee => (
            <div className='employee-details' id={props.id}>
                <div className='employee-header'>
                    <Card className="pro-image"><div><img src={employee.image} alt={employee.name} /></div> </Card>
                    <Card className="pro-detail-personal">
                        <div >
                            <h3><span className='label'>Name:</span>  {employee.name}</h3>
                            <p><span className='label'>Email:</span>  {employee.email}</p>
                            <p><span className='label'>Phone:</span>  {employee.email}</p>
                            <p><span className='label'>DOB:</span>  {employee.dob}</p>
                        </div>
                        <div className="previewResume">
                            <Button href={employee.resume} className="inverse">Preview Resume</Button>
                        </div>
                    </Card>
                    <Card className='pro-status'>
                        <h2>STATUS : {employee.status}</h2>
                    </Card>
                </div>
                <div className='proDetails'>
                <Card >
                    <div className='personalinfo'>
                        <p className='label'>Skills :</p>
                        <div>{employee.skills}</div>
                        <p className='label'>Current Organization :</p>
                        <div>{employee.currentcomp}</div>
                        <p className='label'>Current Designation :</p>
                        <div>{employee.designation}</div>
                        <p className='label'>Department :</p>
                        <div>{employee.department}</div>
                        <p className='label'>Total Exp :</p>
                        <div>{employee.totalexp}</div>
                        <p className='label'>Relavent Exp :</p>
                        <div>{employee.relaventexp} </div>
                        <p className='label'>Notice Period :</p>
                        <div>{employee.noticeperiod} </div>
                        <p className='label'>Referal :</p>
                        <div>{employee.referal}</div>
                        <p className='label'>HR name :</p>
                        <div>{employee.recruiter}</div>
                    </div>
                    <div className='interviewinfo'>
                        <p className='label'>Interview Date :</p>
                        <div>{employee.doi}</div>
                        <p className='label'>First round - Interviewer :</p>
                        <div>{employee.interviewer1}</div>
                        <p className='label'>First round : Feedback</p>
                        <div>{employee.feedback1}</div>
                        <p className='label'>2nd round - Interviewer :</p>
                        <div>{employee.interviewer2}</div>
                        <p className='label'>2nd round : Feedback</p>
                        <div>{employee.feedback2}</div>
                        <p className='label'>3rd round - Interviewer :</p>
                        <div>{employee.interviewer3}</div>
                        <p className='label'>3rd round : Feedback</p>
                        <div>{employee.feedback3}</div>
                        <p className='label'>4th round - Interviewer :</p>
                        <div>{employee.interviewer4}</div>
                        <p className='label'>4th round : Feedback</p>
                        <div>{employee.feedback4}</div>
                        <p className='label'>Final round - Interviewer :</p>
                        <div>{employee.interviewerF}</div>
                        <p className='label'>Final round : Feedback</p>
                        <div>{employee.feedbackF}</div>
                    </div>
                </Card>
                </div>
            </div>
          ))}
</div>
    );
};

export default Employee;
