import React, { useState, useCallback }  from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect, 
  Switch 
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import Button from './shared/components/FormElements/Button';
import MainNavigation from './shared/components/Navigation/MainNavigation'
import Login from './user/pages/Login';
import Employees from './user/pages/Employees';
import AddEmployee from './user/pages/AddEmployee';
import EmployeeProfile from './user/pages/EmployeeProfile';

 

import homebg from '../src/assets/th-hm-bg.png';
import './App.css';
import MainFooter from './shared/components/Navigation/MainFooter';
import { AuthContext } from './shared/context/auth-context';
import AboutUs from './static/pages/AboutUs';
import ContactUs from './static/pages/ContactUs';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loginAccount = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const logoutAccount = useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  let routes;

  if (isLoggedIn) {
    routes = (
        <Switch>
         <Route path="/employees" exact>
           <Employees />
         </Route> 
         <Route path="/addEmployee" exact>
           <AddEmployee />
         </Route>
         <Route path="/:userId/Employee" exact>
           <EmployeeProfile />
         </Route>
         <Redirect to="/employees" />
       </Switch>
    );
  } else {
    routes = (
      <Switch>
      <Route path="/" exact>
      <div className="main">
      <section id="hero" className="d-flex align-items-center">
        <div className="container1">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
              <h1>Employee Self Service</h1>
              <h2>lorem ipsum lorem ipsum lorem ipsum lorem ipsum </h2>
              <div className="d-lg-flex">
              <Button to="/login" >
                  I'm Recruiter
                </Button>
                <Button to="/login" >
                  I'm Interviewer
                </Button>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
              <img  src={homebg} className="home-banner" alt="logo"/>
            </div>
          </div>
        </div>
        </section>
        {/* <h1 className="homeHeading">Hiring Processes<br></br>Made Easy</h1>
        <img src={homebg} className="home-banner" alt="logo" />
        <div>
        <Button to="/login" >
          I'm Recruiter
        </Button>
        <Button to="/login" >
          I'm Interviewer
        </Button>
        </div> */}
      </div>
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/aboutus" exact>
        <AboutUs />
      </Route> 
      <Route path="/contactus" exact>
        <ContactUs />
      </Route>
      <Redirect to="/" />
    </Switch>
    );
  }

  return (
    <AuthContext.Provider
    value={{ isLoggedIn: isLoggedIn, login: loginAccount, logout: logoutAccount }}
  >
      <Router>
      <MainNavigation/>
      <main>
      {routes}
      </main>
      <MainFooter/>
      </Router>
      </AuthContext.Provider>

  );
}

export default App;
