import React from 'react';
import { Link } from 'react-router-dom';

// import Avatar from '../../shared/components/UIElements/Avatar';
import Card from '../../shared/components/UIElements/Card';
import './EmployeeItem.css';

const EmployeeItem = props => {
  return (
    <li className="employee-item" id={props.id}>
      <Card className="employee-item__content">
        <Link to={`/${props.id}/employee`}>
          {/* <div className="employee-item__image">
            <Avatar image={props.image} alt={props.name} />
          </div> */}
          {/* <div className="employee-item__info margin-leftright-10 common-col">
            <h4>{props.name}</h4>
            <div>{props.email}</div>
            <div>
            {props.phone}
            </div>
          </div> */}
          <div className="employee-item__skills margin-leftright-10 common-col">
          {props.id}
          </div>
          <div className="employee-item__skills margin-leftright-10 common-col">
          {props.name}
          </div>
          <div className="employee-item__skills margin-leftright-10 common-col">
          {props.email}
          </div>
          <div className="employee-item__skills margin-leftright-10 common-col">
          {props.phone}
          </div>
          <div className="employee-item__skills margin-leftright-10 common-col">
          {props.depertment}
          </div>
          <div className="employee-item__skills margin-leftright-10 common-col">
          {props.designation}
          </div>
          {/* <div className="employee-item__skills margin-leftright-10 common-col">
          <p>Skills</p>
          {props.skills}
          </div>
          <div className="employee-item__doi margin-leftright-10 common-col">
          <p>Date of Interview</p>
          {props.doi}
          </div>
          <div className="employee-item__doi margin-leftright-10 common-col">
          <p>Interviewers & rounds</p>
          {props.interviewer} - 1st
          </div>
          <div className="employee-item__doi margin-leftright-10 common-col">
          <p>Recruiter Name</p>
          {props.recruiter}
          </div>
          <div className="employee-item__status margin-leftright-10 common-col">
          <p>Status</p>
          {props.status}
          </div> */}
        </Link>
      </Card>
    </li>
  );
};

export default EmployeeItem;
