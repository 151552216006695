import React, { useContext }  from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';

import homei from '../../../assets/icons/home-i.svg';
import './NavLinks.css';

const NavLinks = props => {
    const auth = useContext(AuthContext);

    return <ul className="nav-links">
        <li>
            <NavLink to="/"><img src={homei} alt='home' className='homeicon'/></NavLink>
        </li>
        {!auth.isLoggedIn && (
        <li>
            <NavLink to="/login">Login</NavLink>
        </li>
        )}
        {!auth.isLoggedIn && (
        <li>
            <NavLink to="/aboutus">About Us</NavLink>
        </li>
        )}
        {!auth.isLoggedIn && (
        <li>
            <NavLink to="/contactus">Contact Us</NavLink>
        </li>
        )}
        {auth.isLoggedIn && (
        <li>
            <NavLink to="/employees">Employees</NavLink>
        </li>
        )}
        {auth.isLoggedIn && (
        <li>
            <NavLink to="/addemployee">Add Employee</NavLink>
        </li>
        )}
        {auth.isLoggedIn && (
         <li>
             <button onClick={auth.logout}>Logout</button>
         </li>
        )}
    </ul>;
}

export default NavLinks;