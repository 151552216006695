import React from 'react';

import EmployeeList from '../components/EmployeeList';

const Employees = () => {
  const EMPS = [
    {
      id: 'P2310',
      name: 'Chandra Sekhar Nath',
      email: 'cnath@pfsweb.com',
      phone: '9999999999',
      depertment: 'UI',
      designation: 'SSE'
    },
    {
      id: 'P0007',
      name: 'Shaminder bedi',
      email: 'sbedi@pfsweb.com',
      phone: '9999999999',
      depertment: 'UI',
      designation: 'DM'
    }
  ];

  return <EmployeeList items={EMPS} />;
};

export default Employees;
