import React from 'react';

import Employee from '../components/Employee';

const EmployeeProfile = () => {
  const EMP = [
    {
      id: 'c1',
      name: 'Niti mohan',
      email: 'nm@gmail.com',
      image: 'https://images.pexels.com/photos/839011/pexels-photo-839011.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
      phone: '9999999999',
      skills: 'HTML,CSS,JAVASCRIPT, React , Json , ES6 , Bootstrap',
      designation: 'SSE',
      department: 'UI',
      totalexp: '6',
      relaventexp: '3',
      currentcomp: 'Amazon Development Centre India Pvt Ltd',
      dob: '18/07/1989',
      doi: '30/04/2020',
      noticeperiod: '30 days',
      referal: 'Ajay Devgan',
      interviewer1: 'Chandra sekhar nath',
      recruiter: 'Yashodha',
      status: 'In Process'
    }
  ];

  return <Employee items={EMP} />;
};

export default EmployeeProfile;
