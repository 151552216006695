import React from 'react';

import EmployeeItem from './EmployeeItem';
import Card from '../../shared/components/UIElements/Card';
import './EmployeeList.css';

const EmployeeList = props => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No users found.</h2>
        </Card>
      </div>
    );
  }

  return (
    <ul className="employees-list">
      <li className="employee-item">
      <div className="card employee-item__content">
        <div className="headerrow">
        <div className="employee-item__skills margin-leftright-10 common-col"><p>EMP ID</p></div>
        <div className="employee-item__skills margin-leftright-10 common-col"><p>Namr</p></div>
        <div className="employee-item__skills margin-leftright-10 common-col"><p>Email</p></div>
        <div className="employee-item__skills margin-leftright-10 common-col"><p>Contact no</p></div>
        <div className="employee-item__skills margin-leftright-10 common-col"><p>depertment</p></div>
        <div className="employee-item__skills margin-leftright-10 common-col"><p>designation</p></div>
        </div>
      </div>
      </li>
      {props.items.map(user => (
        <EmployeeItem
          key={user.id}
          id={user.id}
          // image={user.image}
          name={user.name}
          email={user.email}
          depertment={user.email}
          designation={user.email}
          phone={user.phone}
          // skills={user.skills}
          // doi={user.doi}
          // interviewer={user.interviewer}
          // recruiter={user.recruiter}
          // status={user.status}
        />
      ))}
    </ul>
  );
};

export default EmployeeList;
